import queryString from 'query-string';

const sourcesId = 'sources_edmk';
const querySourceId = 's_edmk';

export const saveSource = () => {
  const referrer = window.document.referrer;

  const queryParams = queryString.parse(window.location.search);

  const queryStringSource = queryParams[querySourceId];

  if (referrer || queryStringSource) {
    const sources_edmk = localStorage.getItem(sourcesId);

    const currentSourcesStored = sources_edmk ? JSON.parse(sources_edmk) : [];

    const currentSources =
      currentSourcesStored && Array.isArray(currentSourcesStored)
        ? currentSourcesStored
        : [];

    const newSources = [...currentSources];

    const filterSource = (source) =>
      source &&
      !source.includes('://www.eddisrupt.com') &&
      !source.includes('://eddisrupt.com')
        ? source
        : undefined;

    const lastSource =
      newSources && newSources?.length > 0
        ? newSources[newSources.length - 1]
        : null;

    const sourceToAdd = {
      referrerSource: filterSource(referrer),
      querySource: filterSource(queryStringSource)
    };

    // if the sourceToAdd is deep equal to the last source, dont add, to avoid repetitions on refresh, etc
    if (
      lastSource &&
      JSON.stringify(lastSource) == JSON.stringify(sourceToAdd)
    ) {
      // do nothing
    } else {
      newSources.push(sourceToAdd);

      localStorage.setItem(sourcesId, JSON.stringify(newSources));
    }
  }
};

export const getSources = () => {
  try {
    return JSON.parse(localStorage.getItem(sourcesId));
  } catch (err) {
    return undefined;
  }
};
