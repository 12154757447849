import React from 'react';
import './src/styles/normalize.css';
import './src/styles/styles2.css';
import './src/styles/styles1.css';
import FeedbackProvider from './src/containers/context/FeedbackContext/FeedbackContext';
import UserContextProvider from './src/containers/UserContext/UserContext';

export const wrapRootElement = ({ element }) => (
  <FeedbackProvider>
    <UserContextProvider>{element}</UserContextProvider>
  </FeedbackProvider>
);
