
import React from "react";
import { Snackbar, SnackbarContent, CircularProgress } from "@material-ui/core";
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';


const FeedbackSnackBar = ({ text, isOpen, backgroundColor, onClose, isLoading, isError }) => {
    
    return (
        <Snackbar
            anchorOrigin={{
                vertical: "top",
                horizontal: "right"
            }}
            autoHideDuration={4000}
            open={isOpen}
            onClose={onClose}
            ContentProps={{
                "aria-describedby": "message-id"
            }}
        >
            <div style={{zIndex: '4000'}}>
                <Snackbar_Content
                    isLoading={isLoading}
                    isError={isError}
                    text={text}
                    backgroundColor={backgroundColor}
                />
            </div>
        </Snackbar>
    )
  }

  export default FeedbackSnackBar;

  
  const Snackbar_Content = ({ isError, isLoading, text, backgroundColor }) => {

    let message;

    if(isLoading) {
        message = <div><CircularProgress size={20}/></div>;
    }

    else {
        message = (
            <div style={{display: 'flex', alignItems: 'center'}}>
                <div style={{margin: '2px 8px 0px 0px'}}>
                    {isError ? 
                        <ErrorOutlineIcon style={{color: 'red'}} />
                    
                    : 
                        <CheckBoxIcon style={{color: 'green'}} /> 
                    }
                </div> 
                <div>
                    { text }
                </div>
            </div>
        );
    }
    
    return (
        <SnackbarContent
            message={message}
            style={{ backgroundColor: backgroundColor, color: 'black' }}
        />
    )
  }

