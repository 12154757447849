import React, { createContext, useEffect } from 'react';
import useSetState from '../../utils/useSetState';
import { saveSource } from '../../utils/tracking';

export const UserContext = createContext();

const UserProvider = (props) => {
  const [state, setState] = useSetState(null);

  useEffect(() => {
    try {
      saveSource();
    } catch (err) {
      //
    }
  }, []);

  return (
    <UserContext.Provider
      value={{ userGlobalState: state, setUserGlobalState: setState }}
    >
      {props.children}
    </UserContext.Provider>
  );
};

export default UserProvider;
